import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnrollmentList } from '../models/enrollment-list.model';
import { HttpResponseErrorHandlerService } from '../../shared/services/http-response-error-handler.service';
import { Enrollment } from '../models/enrollment.model';

@Injectable()
export class EnrollmentsService {

  constructor(private httpService: HttpClient,
    private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
  }

  getAllEnrollments(): Observable<Array<EnrollmentList>> {
    return this.httpService.get(`${environment.api}/Enrollments`)
      .pipe(
        map((enrollment: any): Array<EnrollmentList> => enrollment),
        catchError(e => this.httpResponseErrorHandler.HandleResponseError(e))
      );
  }

  getEnrollmentByLessonId(lessonId: number): Observable<Enrollment> {
    return this.httpService.get(`${environment.api}/Lessons/${lessonId}/MyEnrollment`, {})
      .pipe(
        map((enrollment: any): Enrollment => enrollment.data)
      );
  }
}
