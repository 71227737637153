import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpResponseErrorHandlerService } from '../../shared/services/http-response-error-handler.service';
import { EnrollmentLotteryStateModel } from '../models/enrollment-lottery-state.model';

@Injectable({
    providedIn: 'root'
})
export class LessonsLotteryService {

    constructor(private httpService: HttpClient,
        private httpResponseErrorHandler: HttpResponseErrorHandlerService) {
    }

    enrollForLessonLottery(lessonId: number): Observable<any> {
        return this.httpService.post(`${environment.api}/Lessons/${lessonId}/Lottery/Enrollment`, {})
    }

    cancelLotteryEnrollment(lessonId: number): Observable<any> {
        return this.httpService.delete(`${environment.api}/Lessons/${lessonId}/Lottery/Enrollment`, {})
    }

    getEnrollmentLotteryState(lessonId: number): Observable<EnrollmentLotteryStateModel> {
        return this.httpService.get<EnrollmentLotteryStateModel>(`${environment.api}/Lessons/${lessonId}/Lottery/Enrollment`);
    }
}
