<div *ngIf="dataLoaded">
  <div class="container" style="min-height: 500px;">
    <div class="page-header mb-5">
      <h1>{{lesson.sportName}}: {{lesson.title}}</h1>
    </div>

    <app-enrollment-container [lesson]="lesson" [missingSkills]="missingSkills" [memberships]="memberships"
      (enrollmentChanges)="loadLesson()" (liveStreamShow)="showLiveStreamTab()">
    </app-enrollment-container>

    <ul class="nav nav-tabs my-3">
      <li style="cursor: pointer;" (click)="activeTab = 'offer'" class="nav-item nav-pills-asvz" role="presentation"><a
          [ngClass]="{'active': activeTab == 'offer'}" class="nav-link">{{'Sportoffer' | transloco }}</a></li>
      <li style="cursor: pointer;" (click)="showLiveStreamTab()" class="nav-item nav-pills-asvz" role="presentation"
        *ngIf="this.lesson.isLiveStream && userIsLoggedIn && account.hasActiveMembership && lesson.status != lessonStatusEnum.Canceled">
        <a [ngClass]="{'active': activeTab == 'livestream'}" class="nav-link">{{'LiveStream.LiveStream' | transloco
          }}</a></li>
      <li style="cursor: pointer;" (click)="activeTab = 'tlTool'; openTlTool()" class="nav-item nav-pills-asvz"
        role="presentation"><a [ngClass]="{'active': activeTab == 'tlTool'}" class="nav-link"><img
            src="assets/img/tl-logo.svg" style="height: 18px;" /> {{'TlToolTab' | transloco}}</a></li>
    </ul>

    <ng-container *ngIf=" activeTab == 'livestream'">
      <div class="tab-content mb-5">
        <div class="tab-pane active" id="liveStream">
          <app-show-live-stream [liveStream]="liveStream" [lesson]="lesson"></app-show-live-stream>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'tlTool'">
      <div>{{'RedirectToTlToolMsg' | transloco}}</div>
    </ng-container>

    <ng-container *ngIf=" activeTab == 'offer'">
      <div class="tab-content mb-5">
        <div class="tab-pane active" id="eventDetails">
          <div class="row">
            <div class="col-sm-4">
              <div class="card">
                <div class="card-header">{{'Lesson' | transloco}}</div>
                <div class="card-body event-properties">
                  <app-lesson-properties-display [lesson]="lesson"></app-lesson-properties-display>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="card mb-3" *ngIf="missingSkills.length || lesson.requiredSkills.length">
                <div class="card-header">{{'Prerequisites' | transloco}}</div>
                <div class="card-body">
                  <app-prerequisites-view [requiredSkills]="missingSkills" [eventSport]="lesson.sportName"
                    [eventTitle]="lesson.title" [eventStartDate]="lesson.starts" [eventEndDate]="lesson.ends"
                    [registrationStartDate]="lesson.enrollmentFrom">
                  </app-prerequisites-view>
                </div>
              </div>
              <!-- Details (this is markup)-->
              <div class="card">
                <div class="card-header">{{'Details' | transloco}}</div>
                <div class="card-body">
                  <app-details-view [details]="lesson.details"></app-details-view>
                </div>
                <div class="card-footer">
                  <app-details-view [details]="lesson.detailsAddition"></app-details-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<app-spinner *ngIf="!dataLoaded" margin="100px auto"></app-spinner>