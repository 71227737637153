<app-enrollment-messages *ngIf="dataLoaded"></app-enrollment-messages>

<ng-container *ngIf="dataLoaded && enrollmentProperties">

    <div class="alert alert-danger" *ngIf="lesson.status === lessonStatusEnum.Canceled">
        {{'LessonMessages.LessonCancelledMsg' | transloco}}
    </div>

    <!-- Lesson enrollment -->
    <ng-container *ngIf="lesson.status !== lessonStatusEnum.Canceled && lesson.enrollmentEnabled">
        <ng-container *ngIf="userIsLoggedIn && enrollmentProperties.lotteryEnrollmentIsOpen">
            <!-- User is allowed to participate in lottery -->
            <div class="alert alert-info" *ngIf="!enrollmentProperties.userIsEnrolledToLottery &&
                enrollmentProperties.userCanEnrollToLottery">
                {{'LessonMessages.LotteryEnrollmentInfoMsg' | transloco}}
            </div>

            <!-- User is part of the lottery -->
            <div class="alert alert-warning" *ngIf="enrollmentProperties.userIsEnrolledToLottery">
                {{'LessonMessages.LotteryEnrolledInfoMsg' | transloco:enrollmentProperties.translateParams}}
            </div>
        </ng-container>
    </ng-container>

    <!-- Normal enrollment -->
    <ng-container *ngIf="lesson.status !== lessonStatusEnum.Canceled && lesson.enrollmentEnabled">

        <div class="alert alert-warning"
            *ngIf="enrollmentProperties.enrollmentIsExpired && !enrollmentProperties.userIsEnrolled">
            {{'LessonMessages.EnrollmentDateExpired' | transloco}}</div>
        <div class="alert alert-warning" *ngIf="!enrollmentProperties.hasSeats && !enrollmentProperties.userIsEnrolled">
            {{'LessonMessages.LessonFullyBookedMsg' |
            transloco}}</div>
        <div class="alert alert-primary"
            *ngIf="!userIsLoggedIn && enrollmentProperties.hasSeats && !enrollmentProperties.enrollmentIsExpired">
            {{'LessonMessages.LoginForEnrollMsg' | transloco}}</div>
        <div class="alert alert-danger"
            *ngIf="userIsLoggedIn && (!enrollmentProperties.userHasValidMembershipForLesson && !lesson.externeAnmeldung)">
            {{'EventMessages.NoValidMembershipForLesson' | transloco}}</div>


        

        <div class="d-flex align-top">
            <div class="me-2">
                <app-lessons-enrollment-button
                    *ngIf="enrollmentProperties.userCanEnroll && enrollmentProperties.enrollmentIsOpen"
                    [enrollmentProperties]="(enrollmentProperties$ | async)!"
                    (buttonClicked)="enrollForLesson()"></app-lessons-enrollment-button>

                <app-lesson-withdraw-button
                    *ngIf="enrollmentProperties.userIsEnrolled && enrollmentProperties.currentEnrollment"
                    [enrollmentProperties]="enrollmentProperties"
                    (buttonClicked)="withdrawFromLesson()"></app-lesson-withdraw-button>


                <ng-container *ngIf="enrollmentProperties.lotteryEnrollmentIsOpen">
                    <!-- User is allowed to participate in lottery -->
                    <button
                        *ngIf="enrollmentProperties.userCanEnrollToLottery && !enrollmentProperties.userIsEnrolledToLottery"
                        class="btn btn-primary" (click)="enrollForLessonLottery()" id="btnRegister">
                        <i class="fa fa-user-plus color-white" aria-hidden="true"></i>
                        {{'LessonActions.EnrollForLessonLottery' | transloco
                        | uppercase}}
                    </button>

                    <!-- User is part of the lottery -->
                    <button
                        *ngIf="enrollmentProperties.userIsEnrolledToLottery && enrollmentProperties.lotteryEnrollmentIsOpen && enrollmentProperties.inLotteryTimeFrame"
                        class="btn btn-primary" (click)="withdrawFromLessonLottery()" id="btnRegister">
                        <i class="fa fa-user-times color-white" aria-hidden="true"></i>
                        {{'LessonActions.WithdrawFromLessonLottery' | transloco | uppercase}}
                    </button>
                </ng-container>
            </div>

            <app-show-live-stream-button (buttonClicked)="showLiveStream()"
                *ngIf="this.lesson.isLiveStream && userIsLoggedIn && (enrollmentProperties.userHasValidMembershipForLesson || lesson.externeAnmeldung)"></app-show-live-stream-button>

        </div>

        <div class="row" *ngIf="enrollmentProperties.userCanEnroll">
            <div class="col-sm-12">
                <div>
                    <span *ngIf="enrollmentProperties.enrollmentIsOpen">{{'LessonMessages.EnrollLessonInfoMsg' |
                        transloco:enrollmentProperties.translateParams}}</span>
                    <span *ngIf="!enrollmentProperties.enrollmentIsOpen">{{'LessonMessages.EnrollLessonNotOpenInfoMsg' |
                        transloco:enrollmentProperties.translateParams}}</span>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="enrollmentProperties.userIsEnrolled">
            <div class="col-sm-12">
                <div>
                    <span *ngIf="enrollmentProperties.withdrawIsExpired">{{'LessonMessages.WithdrawExpiredInfoMsg' |
                        transloco:enrollmentProperties.translateParams}}</span>
                    <span *ngIf="!enrollmentProperties.withdrawIsExpired">{{'LessonMessages.WithdrawInfoMsg' |
                        transloco:enrollmentProperties.translateParams}}</span>
                </div>
            </div>
        </div>

        <div
            *ngIf="userIsLoggedIn && (!enrollmentProperties.userHasValidMembershipForLesson && !lesson.externeAnmeldung)">
            <button class="btn btn-default" routerLink="/memberships">{{'GoToMemberships' | transloco }}</button>
        </div>

        <app-login-button *ngIf="!userIsLoggedIn"></app-login-button>

    </ng-container>


</ng-container>

<app-show-live-stream-button (buttonClicked)="showLiveStream()"
    *ngIf="lesson.isLiveStream && userIsLoggedIn && (enrollmentProperties?.userHasValidMembershipForLesson) && !lesson.enrollmentEnabled && lesson.status !== lessonStatusEnum.Canceled">
</app-show-live-stream-button>